<template>
  <div class="d-flex justify-content-center">
    <b-row :class="`mt-3 ${has_slug ? 'col-12 col-sm-10' : 'col-12 col-lg-8 col-xl-5'}`">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.locationInfluencers') }}</h1>
        <p class="mb-3">{{ $t('creator.locationInfluencersText') }}</p>
        
        <b-form-radio-group class="cursor-pointer" v-model="type_location">
          <b-form-radio class="w-100 text-left mb-1" value="any">
            <strong>{{ $t('creator.everybody') }}</strong>
            <p class="avenir-medium">{{ $t('creator.everybodyText') }}</p>
          </b-form-radio>
          <b-form-radio class="w-100 text-left" value="specific">
            <strong>{{ $t('creator.locationSpecific') }}</strong>
            <p class="avenir-medium">{{ $t('creator.locationSpecificText') }}</p>
          </b-form-radio>
        </b-form-radio-group>
        
        <div class="selected-locations mb-3" v-if="type_location === 'specific' && selected_locations.length > 0">
          <b-badge
            v-for="(loc, index) in selected_locations"
            :key="index"
            variant="primary"
            class="badge-location"
          >
            {{ loc.name }}
            <feather-icon icon="XIcon" @click="removeLocation(index)" />
          </b-badge>
        </div>

        <div class="container-audience-location-filter" v-if="type_location === 'specific'">
          <audience-location-filter
            :location="location"
            :index="0"
            :main_locations_filter="main_locations_filter"
            :show_slider="false"
            @changeLocationAudienceChild="addLocation"
            class="w-100"
          />
        </div>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button
            :disabled="type_location !== 'any' && selected_locations.length === 0"
            variant="blue-button-next-step"
            class="blue-button-next-step my-2"
            @click="save"
          >
            {{ has_slug ? $t('creator.savePreview') : $t('creator.butonNext') }}
          </b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormRadioGroup,
  BFormRadio,
  BBadge
} from 'bootstrap-vue';
import casting_calls_services from '@/services/casting_calls';
import services_search from '@/services/search';

export default {
  name: 'stepLocation',
  components: {
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BBadge,
    AudienceLocationFilter: () => import('@/views/buscador/filters/audience_locations.vue')
  },
  data() {
    return {
      type_location: 'any',
      main_locations_filter: [],
      steps: null,
      casting_call: {},
      casting_call_uuid: '',
      location: {},
      selected_locations: []
    };
  },
  computed: {
    has_slug() {
      return this.$route.params.slug;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    addLocation(data) {
      if (data && data.location && data.location.name) {
        const locationExists = this.selected_locations.some(loc => loc.name === data.location.name);
        if (!locationExists) {
          this.selected_locations.push({ name: data.location.name });
        }
      }
    },
    removeLocation(index) {
      this.selected_locations.splice(index, 1);
    },
    getMainLocationsForFilter() {
      services_search.getMainLocations().then((response) => {
        this.main_locations_filter = response.countries;
      })
    },
    getData() {
      this.getMainLocationsForFilter();
      if (this.has_slug) {
        this.casting_call = {...this.$route.params.casting_call_data}
        this.casting_call_uuid = this.casting_call.uuid;
        this.assignData();
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.casting_call_uuid = this.steps.uuid ? this.steps.uuid : this.$route.params.casting_call_uuid;
        casting_calls_services.getCastingCall(this.casting_call_uuid).then((response) => {
          this.casting_call = response;
          this.casting_call.location = this.casting_call.location || [];
          this.assignData();
        });
      }
    },
    assignData() {
      if (typeof this.casting_call.location === 'string') {
        const locationsArray = this.casting_call.location.split(',').map(loc => loc.trim());
        this.selected_locations = locationsArray.map(loc => ({ name: loc }));
      } else if (Array.isArray(this.casting_call.location)) {
        this.selected_locations = this.casting_call.location.map(loc => ({ name: loc }));
      } else {
        this.selected_locations = [];
      }
      this.type_location = this.selected_locations.length > 0 ? 'specific' : 'any';
    },
    save() {
      const form_data = new FormData();
      if (this.type_location === 'any') {
        form_data.append('remove_location',true);
      } else {
        this.selected_locations.forEach((loc) => {
          form_data.append('location', loc.name);
        });
      }
      casting_calls_services.updateCastingCall(this.casting_call_uuid, form_data).then((response) => {
        if (response.status === 'OK' && !this.has_slug) {
          this.$router.push({ name: 'steps_casting_call', params: { step: 'delivery-deadline' } });
        } else {
          this.$emit('update_campaign', response.response);
        }
      });
    },
  }
};
</script>

<style scoped>
.badge-location {
  margin: 10px;
  height: 20px;
}
.container-audience-location-filter {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
.selected-locations {
  display: flex;
  flex-wrap: wrap;
}
.selected-locations .b-badge {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.selected-locations .b-badge {
  margin-left: 0.5rem;
}
</style>
